<template>
  <page-content>
    <!-- <img src="@/assets/img/e-learn_banner.png" style="width: 100%" /> -->
    <img src="@/assets/main_banner.png" class="mobile" style="width: 100%" />
    <!-- <img src="@/assets/mock/header.png" style="    width: 100%;" /> -->
    <!-- <page-title
      heading="ยินดีต้อนรับเข้าสู่เว็บไซต์ E-LEARNING D.A.R.E. Thailand"
    ></page-title> -->

    <div style="padding-top: 20px;">
      <div style="padding: 30px;">
        <div class="row mb-4">
          <div class="col-4">
            <p class="mt-8" style="padding-left: 10px; font-size: 1.3rem;">
              <b>หลักสูตรการเรียน E-Learning</b>
            </p>
          </div>
          <div class="col-8">
            <b-form-group class="px-2">
              <b-input-group class="input-group-seamless">
                <b-form-input placeholder="ค้นหาหลักสูตร"></b-form-input>

                <b-input-group-text slot="prepend">
                  <font-awesome-icon class="mx-auto" icon="search" />
                </b-input-group-text>

                <!-- <b-input-group-text  slot="append">
              <a href="javascript:void(0)" >
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text> -->
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div
          class="row mb-4"
          style="padding-left: 5%; padding-right: 5%; margin-top: -30px"
        >
          <div class="col-3">
            <a>
              <p
                class="mt-4 text-center"
                align-items-center
                style="
                text-decoration: underline;
                color: #202453;
                text-decoration-color: #202453;
                text-decoration-thickness: 3px;
              "
                @click="buyItem(price)"
              >
                <b>หลักสูตรยอดนิยม</b>
              </p>
            </a>
          </div>
          <div class="col-3">
            <p
              class="mt-4 text-center"
              style="padding-left: 10px; color: #202453"
            >
              หลักสูตรใหม่
            </p>
          </div>
          <div class="col-3">
            <p
              class="mt-4 text-center"
              style="padding-left: 10px; color: #202453"
            >
              หลักสูตรสำหรับเด็ก
            </p>
          </div>
          <div class="col-3">
            <p
              class="mt-4 text-center"
              style="padding-left: 10px; color: #202453"
            >
              เอกสารอื่น ๆ
            </p>
          </div>
        </div>
        <!-- <div class="example-3d">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide
              @clickSlide="onClickCourse(course.id)"
              v-for="course in courses"
              :key="`course_${course.id}`"
              >ชื่อหลักสูตร: {{ course.course_name }}</swiper-slide
            >
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div> -->
        <carousel-3d :controls-visible="true" v-if="courses.length > 0" :clickable="true">
          <slide  v-for="(course, i) in courses" :key="i" :index="i" @onMainSlideClick="onClickCourse(course.id)">
            <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
            <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" src="@/assets/sample.png" @click="onClickCourse(course.id)">
             
        </template>
       
           <!-- <img src="@/assets/sample.png" style="height: 100%;"> -->
          </slide>
           <slide  v-for="(course, i) in courses" :key="`new_${i}`" :index="i+3" @onMainSlideClick="onClickCourse(course.id)">
            <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
            <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" src="@/assets/sample.png" @click="onClickCourse(course.id)">
             
        </template>
       
           <!-- <img src="@/assets/sample.png" style="height: 100%;"> -->
          </slide>
          <!-- <slide :index="1">
            <img src="@/assets/sample.png" style="height: 100%;">
          </slide>
          <slide :index="2">
            <img src="@/assets/sample.png" style="height: 100%;">
          </slide>
          <slide :index="3">
            <img src="@/assets/sample.png" style="height: 100%;">
          </slide>
          <slide :index="4">
            <img src="@/assets/sample.png" style="height: 100%;">
          </slide>
          <slide :index="5">
            <img src="@/assets/sample.png" style="height: 100%;">
          </slide> -->
        </carousel-3d>
      </div>

      <!-- <slide v-for="(course, i) in courses" :key="`course_${course.id}`" :index="i">
            Slide 1 Content
          </slide> -->
      <!-- <div class="row mb-4">
        <div
          class="col-4"
          v-for="course in courses"
          :key="`course_${course.id}`"
        >
          <div>
            <b-card
              overlay
              img-src="https://yuzudigital.com/reason_mock.png"
              text-variant="white"
              footer-bg-variant="primary"
              footer-text-variant="white"
            >
              <div class="text-right">
                <b-button
                  @click="onClickCourse(course.id)"
                  variant="primary"
                  style="padding: 2px;"
                >
                  <p class="m-0" style="font-size: 0.8rem;">ลงทะเบียน</p>
                </b-button>
              </div>
              <template #footer>
                <p>
                  <b> {{ course.course_name }} </b>
                </p>
              </template>
            </b-card>
          </div>
        </div>
      </div> -->
      <!--  <div class="row mb-4">
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
        <div class="col-4">
          <reason-card style="width: 100%"> </reason-card>
        </div>
      </div> -->
      <!-- <br>
      <div style="margin-top: 20px;">
        <h1 class="text-center">
          เพราะโลกไม่เคยหยุดหมุน เราจึงไม่หยุดเรียนรู้
        </h1>
      <img src="https://www.cpuniversityonline.com/images/landing/S8imgSuccess.webp" style="    width: 100%;" />
      </div> -->

      <br />
      <!-- <b-row align-h="center">
        <b-col md="6">
          <a class="card-img-wrapper rounded" href="javascript:void(0)">
            <youtube
              video-id="_mq-5aAL9lM"
              ref="youtube"
              style="width: 100%; height: 400px;"
              
            ></youtube>
          </a>
        </b-col>
        <b-col md="6">
          <a class="card-img-wrapper rounded" href="javascript:void(0)">
            <youtube
              video-id="wtWbMKmb0l0"
              ref="youtube"
              style="width: 100%; height: 400px;"
              
            ></youtube>
          </a>
        </b-col>
      </b-row> -->
      <div style="padding: 0px; padding-top: 20px; background: #B83437;">
        <div style="padding: 30px; padding-top: 0px;">
          <p class="mt-4" style="color: #fff; font-size: 1.2rem;">
            <b>ข่าวประชาสัมพันธ์</b>
          </p>
          <br />
          <div class="row mb-4">
            <!-- <news></news> -->
            <div class="col-4">
              <news-card style="width: 100%"> </news-card>
            </div>
            <div class="col-4">
              <news-card style="width: 100%"> </news-card>
            </div>
            <div class="col-4">
              <news-card style="width: 100%"> </news-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
</template>

<script>
import PageContent from "../components/layout/PageContent";
import "swiper/swiper-bundle.css";
import { Carousel3d, Slide } from "vue-carousel-3d";
// import PageTitle from "../components/layout/PageTitle";
// import BannerImageHome from "../components/carousel/BannerImageHome";
// import News from "../components/carousel/News";
// import Reason from "../components/carousel/Reason";

// import ReasonCard from "../components/card/ReasonCard";
import NewsCard from "../components/card/NewsCard";
import axios from "axios";

export default {
  components: {
    PageContent,
    // PageTitle,
    // BannerImageHome,
    // News,
    // ReasonCard,
    NewsCard,
    Carousel3d,
    Slide,
    // Reason,
  },
  // directives: {
  //   swiper: directive,
  // },

  data() {
    return {
      swiperOption: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      courses: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      var promise = await axios.get(
        `https://e-learnning-service-temp.yuzudigital.com/courses`
      );
      console.log(promise.data);
      this.courses = promise.data.data;

      // this.courseForm.lessonList.push({
      //   lesson_name: "",
      //   id: ""
      // })
      // const promise = await Survey.api().findAll(
      //   {
      //     ...additionalParams,
      //     limit: ctx.perPage,
      //     offset: (ctx.currentPage - 1) * ctx.perPage,
      //   },
      //   { save: false }
      // );
    },
    async reasonDetail() {
      location.href = "/reason-detail";
    },
    onClickCourse(courseId) {
      console.log(courseId);
      this.$router.push(`/reason-detail?courseId=${courseId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 1440px !important;

    @media only screen and (max-width: 1660px) and (min-width: 1420px) {
      max-width: 1140px !important;
      .mobile {
        display: none;
      }
    }
    @media only screen and (max-width: 1419px) and (min-width: 1140px) {
      max-width: 960px !important;
      .mobile {
        display: none;
      }
    }

    @media only screen and (max-width: 1139px) and (min-width: 770px) {
      max-width: 760px !important;
      .desktop {
        display: none;
      }
    }

    @media (max-width: 760px) {
      max-width: 360px !important;
      .desktop {
        display: none;
      }
    }
  }
}

.example-3d {
  width: 100%;
  height: 400px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20;
    background-color: #2c8dfb;
    background-position: center;
    background-size: cover;
    color: #fff;
  }

  .swiper-pagination {
    /deep/ .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #fff;
    }
  }
}
</style>
